<script setup lang="ts">
import { BaseIcon } from '@legacy-studio/library';
import { TraderDrawerList } from '~/src/widgets/terminal/hooks';
import { TraderDrawers } from '~/src/widgets/terminal/enum';
import { useDrawer } from '~/src/widgets/terminal/useDrawer';

const drawerButtons = [
	{ key: TraderDrawers.HelpForm, title: TraderDrawerList[TraderDrawers.HelpForm]?.title },
	{ key: TraderDrawers.HelpBot, title: TraderDrawerList[TraderDrawers.HelpBot]?.title },
];

const { openDrawer } = useDrawer();
</script>

<template>
	<div class="flex flex-col gap-6 pt-4">
		<button
			v-for="button in drawerButtons"
			:key="button.key"
			type="button"
			class="text-gray-400 hover:text-white leading-tight text-sm font-normal flex justify-between items-center pb-3 border-b border-gray-600"
			@click="openDrawer(button.key)"
		>
			<span>{{ button.title }}</span>
			<BaseIcon
				name="base--fill--chevron-right"
				class="text-[18px]"
			/>
		</button>
	</div>
</template>
